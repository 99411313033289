/* styles for App */

.mobile-btns {
  position: absolute;
  top: 20px;
  right: 20px;
}

.pdf {
  height: 600px;
}

@media screen and (min-width: 768px) {
  .inner-body {
    padding-left: 80px;
  }

  .contact-info {
    padding-left: 80px;
  }

  .pdf {
    width: 64%;
  }

  .hr-about {
    width: 65%;
  }

  .hr-exprience {
    width: 64%;
  }

  .hr-portfolio {
    width: 66%;
  }

  .about-hr {
    width: 30%;
  }

  .about-text {
    width: 75%;
  }

  .project-card {
    width: 75%;
  }

  .edu-tmu {
    gap: 362px;
  }

  .edu-bhag {
    gap: 375px;
  }

  .edu-microverse {
    gap: 298px;
  }

  .hr-blog {
    width: 62%;
  }

  .card-img {
    width: 50%;
  }

  .code-snippet {
    width: 40%;
  }

  .post-img {
    width: 150%;
  }
}

@media screen and (min-width: 1024px) {
  .inner-body {
    padding-left: 200px;
  }

  .contact-info {
    padding-left: 200px;
  }
}
